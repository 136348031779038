import { createColumnHelper } from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'src/components/button/Button'
import { ConfirmationModal } from 'src/components/confirmation-modal/ConfirmationModal'
import { Icon } from 'src/components/icon/Icon'
import { EditLeadListModal } from 'src/components/leads-list/edit/EditLeadListModal'
import { NewLeadListModal } from 'src/components/leads-list/new/NewLeadListModal'
import { PageLayout } from 'src/components/page-layout/PageLayout'
import { StatisticCard } from 'src/components/statistic-card/StatisticCard'
import { Table } from 'src/components/table/Table'
import { ILeadListData, useLeadListData } from 'src/service/lead-list/useLeadListData'
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  StatisticFinished,
  StatisticInProgress,
  StatisticNotStarted,
  StatisticUnfinished
} from 'src/shared/icons'
import { getFirebaseFormattedDateAndTime, resolveLeadListStatus } from 'src/shared/utils'
import styled from 'styled-components'

export const LeadsListPage = () => {
  const { getLeadLists, removeLeadList } = useLeadListData()
  const columnHelper = createColumnHelper<ILeadListData>()

  const [leadLists, setLeadLists] = useState<ILeadListData[]>([])
  const [createListModal, setCreateListModal] = useState<boolean>(false)
  const [leadListDeleteModal, setLeadListDeleteModal] = useState<boolean>(false)
  const [leadListIdToDelete, setLeadListIdToDelete] = useState<string | null>(null)
  const [leadListEditModal, setLeadListEditModal] = useState<boolean>(false)
  const [leadListIdToEdit, setLeadListIdToEdit] = useState<string | null>(null)

  const onRefetch = useCallback(async () => {
    const data = await getLeadLists()
    if (data) {
      setLeadLists(data)
    }
  }, [getLeadLists])

  useEffect(() => {
    onRefetch()
  }, [onRefetch])

  const onCreate = useCallback(() => {
    setCreateListModal(false)
    onRefetch()
  }, [onRefetch])

  const onEditLeadList = (id: string) => {
    setLeadListIdToEdit(id)
    setLeadListEditModal(true)
  }

  const onDeleteLeadList = (id: string) => {
    setLeadListIdToDelete(id)
    setLeadListDeleteModal(true)
  }

  const onConfirmDeletion = async () => {
    if (leadListIdToDelete) {
      await removeLeadList(leadListIdToDelete)
      setLeadListDeleteModal(false)
      setLeadListIdToDelete(null)
      onRefetch()
    }
  }

  const onCancelDeletion = () => {
    setLeadListIdToDelete(null)
    setLeadListDeleteModal(false)
  }

  const onConfirmEdit = async () => {
    setLeadListEditModal(false)
    setLeadListIdToEdit(null)
    onRefetch()
  }

  const onCancelEdit = () => {
    setLeadListIdToEdit(null)
    setLeadListEditModal(false)
  }

  const columns = [
    columnHelper.accessor('name', {
      header: 'List name'
    }),
    columnHelper.accessor('updated_at', {
      header: 'Imported Date',
      cell: (updated_at) => getFirebaseFormattedDateAndTime(updated_at.getValue())
    }),
    columnHelper.accessor('leads', {
      header: 'Leads',
      cell: () => 'n/a'
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (status) => resolveLeadListStatus(status.getValue())
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: (id) => (
        <div style={{ display: 'flex', gap: 10 }}>
          <Icon size={18} icon={EditIcon} onClick={() => onEditLeadList(id.getValue())} />
          <Icon size={18} icon={DeleteIcon} onClick={() => onDeleteLeadList(id.getValue())} />
        </div>
      ),
      enableSorting: false
    })
  ]

  return (
    <>
      <PageLayout
        title='Leads Lists'
        action={
          <Button onClick={() => setCreateListModal(true)}>
            <Icon icon={AddIcon} size={16} /> Create new List
          </Button>
        }
      >
        <StatisticRow>
          <StatisticCard label='In Progress' value='2' icon={StatisticInProgress} />
          <StatisticCard label='Finished' value='4,412' icon={StatisticFinished} />
          <StatisticCard label='Unfinished' value='329' icon={StatisticUnfinished} />
          <StatisticCard label='Not Started' value='123' icon={StatisticNotStarted} />
        </StatisticRow>

        <Table data={leadLists} columns={columns} defaultSort={[{ id: 'name', desc: false }]} />
      </PageLayout>
      {leadListIdToEdit && (
        <EditLeadListModal
          visible={leadListEditModal}
          leadListId={leadListIdToEdit}
          onClose={onCancelEdit}
          onSuccess={onConfirmEdit}
        />
      )}
      <NewLeadListModal visible={createListModal} onClose={() => setCreateListModal(false)} onSuccess={onCreate} />
      <ConfirmationModal
        visible={leadListDeleteModal}
        title='Delete lead list'
        message='Are you sure you want to delete this lead list'
        onClose={onCancelDeletion}
        onConfirm={onConfirmDeletion}
      />
    </>
  )
}

const StatisticRow = styled.div`
  width: ${({ theme }) => theme.width.full};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`
