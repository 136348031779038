import { Card } from 'src/components/card/Card'
import { PageLayout } from 'src/components/page-layout/PageLayout'
import styled from 'styled-components'
import { DownloadExtension } from './steps/DownloadExtension'
import { ImportLeads } from './steps/ImportLeads'
import { InviteTeam } from './steps/InviteTeam'
import { FirstCampaign } from './steps/FirstCampaign'
import { Modal } from 'src/components/modal/Modal'
import { useState } from 'react'
import { Walkthrough } from 'src/components/walkthrough/Walkthrough'
import { useUser } from 'src/user/UserContext'
import { useUserData } from 'src/service/user/useUserData'

export const SetupPage = () => {
  const { user, completedWalkthrough, setCompletedWalkthrough } = useUser()
  const { updateUser } = useUserData()
  const [walkthroughModalVisible, setWalkthroughModalVisible] = useState<boolean>(!completedWalkthrough)

  const onWalkthroughModalClose = async () => {
    setWalkthroughModalVisible(false)
    setCompletedWalkthrough(true)
    await updateUser(user!.id, { walkthrough: true })
  }

  return (
    <>
      <PageLayout title='Setup'>
        <Row>
          <Card
            title='Download Chrome extension'
            description='Head start downloading our Chrome extension from the Chrome store.'
          >
            <DownloadExtension />
          </Card>
          <Card
            title='Import leads'
            description='Import your leads directly from your LinkedIn account. The process is automatic, click the button to open a new window.'
          >
            <ImportLeads />
          </Card>
        </Row>
        <Row>
          <Card title='Invite your team' description='Invite via email'>
            <InviteTeam />
          </Card>
          <Card title='Start your first campaign'>
            <FirstCampaign />
          </Card>
        </Row>
      </PageLayout>
      <Modal
        noPadding
        visible={walkthroughModalVisible}
        shouldCloseOnOutsideClick={false}
        onClose={onWalkthroughModalClose}
      >
        <Walkthrough onDone={onWalkthroughModalClose} />
      </Modal>
    </>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`
