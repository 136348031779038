import styled, { css } from 'styled-components'
import { Button } from '../button/Button'
import { TWalkthrough } from 'src/shared/walkthrough-config'
import { FC } from 'react'

interface IStepper {
  item: TWalkthrough
  onClick: () => void
}

export const Stepper: FC<IStepper> = ({ item, onClick }) => {
  return (
    <Container>
      <BgImage $url={item.background} />
      <Separator />
      <DotContainer>
        <Dots $fill={item.step >= 0} />
        <Dots $fill={item.step >= 1} />
        <Dots $fill={item.step >= 2} />
        <Dots $fill={item.step >= 3} />
      </DotContainer>
      <Main>
        <Title>{item.title}</Title>
        <SubTitle>{item.subtitle}</SubTitle>
        <Button onClick={onClick}>{item.button}</Button>
      </Main>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const BgImage = styled.div<{ $url: string }>`
  background-image: ${({ $url }) => `url(${$url})`};
  width: 100%;
  height: 350px;
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: 0.5s;
`

const Separator = styled.div`
  width: 100%;
  height: 50px;
  margin-top: -50px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`

const DotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px;
  margin-top: 48px;
`

const Dots = styled.div<{ $fill?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.primary};
  transition: 0.5s;

  ${({ $fill }) =>
    !$fill &&
    css`
      opacity: 0.5;
    `}
`

const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 48px;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.XXL};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  text-align: center;
  color: ${({ theme }) => theme.color.primary};
  margin-top: 24px;
  margin-bottom: 14px;
  transition: 0.5s;
`

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.font.size.S};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-align: center;
  color: '#777777';
  margin-bottom: 24px;
  transition: 0.5s;
`
