import { COLLECTION, LEAD_LIST_STATUS, TLeadListStatus } from 'src/shared/constants'
import { useFirebaseData } from '../firebase/hooks/useFirebaseData'
import { Timestamp } from 'firebase/firestore'
import { useCallback, useMemo } from 'react'

export interface ILeadListData {
  id: string
  name: string
  status: TLeadListStatus
  leads: any[]
  created_at: Timestamp
  updated_at: Timestamp
}

interface ISaveLeadListData {
  name: string
}

export const useLeadListData = () => {
  const { getAll, get, store, update, destroy } = useFirebaseData(COLLECTION.LEAD_LISTS)

  const defaultLeadListData = useMemo(
    () => ({
      id: '',
      status: LEAD_LIST_STATUS.NOT_STARTED,
      leads: [],
      created_at: Timestamp.now(),
      updated_at: Timestamp.now()
    }),
    []
  )

  const getLeadLists = useCallback(async () => {
    return await getAll<ILeadListData>()
  }, [getAll])

  const getLeadList = useCallback(
    async (id: string) => {
      return await get<ILeadListData>(id)
    },
    [get]
  )

  const saveLeadList = useCallback(
    async (data: ISaveLeadListData) => {
      return await store<ILeadListData>({
        ...defaultLeadListData,
        ...data
      })
    },
    [store, defaultLeadListData]
  )

  const updateLeadList = useCallback(
    async (id: string, data: Partial<ILeadListData>) => {
      return await update<Partial<ILeadListData>>(id, data)
    },
    [update]
  )

  const removeLeadList = useCallback(
    async (id: string) => {
      return await destroy(id)
    },
    [destroy]
  )

  return {
    getLeadLists,
    getLeadList,
    saveLeadList,
    updateLeadList,
    removeLeadList
  }
}
