import { ROLE_TYPE } from 'src/routes'
import { TAccountPlan, TLeadListStatus } from './constants'
import { Timestamp } from 'firebase/firestore'
import dayjs from 'dayjs'

export const retrieveAvatarUrl = (firstName: string = 'D', lastName: string = 'I'): string => {
  return `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=0C2440&color=E0E2E7&size=512`
}

export const retrieveDisplayName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  return `Digitalics Innovation User`
}

export const resolvePlan = (plan: TAccountPlan): string => {
  switch (plan) {
    case 'FREE':
      return 'Free'
    case 'BUSINESS':
      return 'Business'
    default:
      return 'n/a'
  }
}

export const resolveRole = (role: ROLE_TYPE): string => {
  switch (role) {
    case 'ADMIN':
      return 'Admin'
    case 'MEMBER':
      return 'Member'
    default:
      return 'n/a'
  }
}

export const resolveLeadListStatus = (status: TLeadListStatus): string => {
  switch (status) {
    case 'NOT_STARTED':
      return 'Not started'
    case 'IN_PROGRESS':
      return 'In progress'
    case 'IMPORTED':
      return 'Imported'
    case 'UNFINISHED':
      return 'Unfinished'
    case 'FINISHED':
      return 'Finished'
    case 'ERROR':
      return 'Error'
    default:
      return 'n/a'
  }
}

export const resolveFirebaseDateToDate = (timestamp: Timestamp): Date => {
  return new Date(timestamp.seconds * 1000)
}

export const getFirebaseFormattedDateAndTime = (timestamp: Timestamp): string => {
  const date = resolveFirebaseDateToDate(timestamp)
  const dayjsDate = dayjs(date)
  return dayjsDate.format('DD MMM YYYY - HH:mm')
}
