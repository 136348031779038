export const FIREBASE_ERROR_MAP = [
  {
    key: 'auth/session-cookie-expired',
    value: 'Session cookie expired.'
  },
  {
    key: 'auth/session-cookie-revoked',
    value: 'Session cookie revoked.'
  },
  {
    key: 'auth/invalid-credential',
    value: 'Invalid credentials.'
  },
  {
    key: 'auth/email-already-in-use',
    value: 'Email is already in use.'
  },
  {
    key: 'auth/internal-error',
    value: 'Internal error.'
  },
  {
    key: 'auth/email-already-exists',
    value: 'Email already exists.'
  },
  {
    key: 'auth/invalid-email',
    value: 'Invalid email.'
  },
  {
    key: 'auth/invalid-password',
    value: 'Invalid password.'
  },
  {
    key: 'auth/invalid-phone-number',
    value: 'Invalid phone number.'
  },
  {
    key: 'auth/phone-number-already-exists',
    value: 'Phone number already exists.'
  },
  {
    key: 'auth/user-not-found',
    value: 'User not found.'
  },
  {
    key: 'auth/popup-closed-by-user',
    value: 'Sign in with Google canceled.'
  },
  {
    key: 'auth/unauthorized-domain',
    value: 'Unauthorized domain.'
  }
]
