import { Form, Formik } from 'formik'
import { Button } from '../button/Button'
import { InputField } from '../input/Input'
import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useUser } from 'src/user/UserContext'

interface IResetPasswordForm {
  password: string
  confirm_password: string
}

const validationSchema = Yup.object({
  password: Yup.string().min(8, 'Password should be minimum of 8 characters').required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm password is required')
})

export const ResetPasswordForm: FC<{ onPasswordReset: (value: boolean) => void }> = ({ onPasswordReset }) => {
  const location = useLocation()
  const { resetPassword } = useUser()
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const mode = queryParams.get('mode')
    const oobCode = queryParams.get('oobCode')
    if (oobCode && mode === 'resetPassword') {
      setCode(oobCode)
    }
  }, [location])

  const onSubmit = async (values: IResetPasswordForm) => {
    const { password } = values
    const passwordReset = await resetPassword(code, password)
    onPasswordReset(passwordReset)
  }

  return (
    <Formik<IResetPasswordForm>
      initialValues={{ password: '', confirm_password: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form noValidate>
          <InputField name='password' label='New Password' type='password' placeholder='Your password...' />
          <InputField name='confirm_password' label='Confirm Password' type='password' placeholder='Your password...' />
          <Button type='submit' disabled={!isValid || isSubmitting}>
            Reset password
          </Button>
        </Form>
      )}
    </Formik>
  )
}
