import { ElementType } from 'react'
import { CampaignsPage } from 'src/screens/campaigns/CampaignsPage'
import { DashboardPage } from 'src/screens/dashboard/DashboardPage'
import { ForgotPasswordPage } from 'src/screens/forgot-password/ForgotPasswordPage'
import { LandingPage } from 'src/screens/landing/LandingPage'
import { LeadsListPage } from 'src/screens/leads-list/LeadsListPage'
import { LoginPage } from 'src/screens/login/LoginPage'
import { MessagingPage } from 'src/screens/messaging/MessagingPage'
import { RegisterPage } from 'src/screens/register/RegisterPage'
import { ResetPasswordPage } from 'src/screens/reset-password/ResetPasswordPage'
import { SetupPage } from 'src/screens/setup/SetupPage'
import { SearchPage } from 'src/screens/search/SearchPage'
import { CampaignsIcon, DashboardIcon, LeadsListIcon, MessagingIcon, SetupIcon, UserIcon } from 'src/shared/icons'
import { ProfilePage } from 'src/screens/profile/ProfilePage'
import { UsersPage } from 'src/screens/users/UsersPage'

export const ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER'
} as const

export type ROLE_TYPE = keyof typeof ROLES

type ROUTE_TYPE = {
  [name: string]: {
    path: string
    Component: ElementType
    label?: string
    icon?: string | null
    roles?: ROLE_TYPE[]
  }
}

export const ROUTES: ROUTE_TYPE = {
  LandingPage: {
    path: '/',
    Component: LandingPage
  },
  LoginPage: {
    path: '/login',
    Component: LoginPage
  },
  RegisterPage: {
    path: '/register',
    Component: RegisterPage
  },
  ForgotPasswordPage: {
    path: '/forget-password',
    Component: ForgotPasswordPage
  },
  ResetPasswordPage: {
    path: '/reset-password',
    Component: ResetPasswordPage
  },
  SetupPage: {
    path: '/setup',
    label: 'Setup',
    icon: SetupIcon,
    Component: SetupPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  UsersPage: {
    path: '/users',
    label: 'Users',
    icon: UserIcon,
    Component: UsersPage,
    roles: [ROLES.ADMIN]
  },
  SearchPage: {
    path: '/search/:query',
    Component: SearchPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  DashboardPage: {
    path: '/dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
    Component: DashboardPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  LeadsListPage: {
    path: '/leads-list',
    label: 'Leads List',
    icon: LeadsListIcon,
    Component: LeadsListPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  CampaignsPage: {
    path: '/campaigns',
    label: 'Campaigns',
    icon: CampaignsIcon,
    Component: CampaignsPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  MessagingPage: {
    path: '/messaging',
    label: 'Messaging',
    icon: MessagingIcon,
    Component: MessagingPage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  },
  ProfilePage: {
    path: '/profile',
    Component: ProfilePage,
    roles: [ROLES.ADMIN, ROLES.MEMBER]
  }
}

export const isPathAllowedForRole = (path: string, roles: ROLE_TYPE[]) => {
  const routeKeyMatch = Object.keys(ROUTES).find((key) => ROUTES[key].path === path)

  if (!routeKeyMatch) {
    return false
  }

  const route = ROUTES[routeKeyMatch]
  if (!route.roles) {
    return true
  }

  return route.roles.some((role) => roles.includes(role))
}

export const getPublicPaths = () => {
  return Object.values(ROUTES)
    .filter((r) => r.roles === undefined)
    .map((i) => i.path)
}
