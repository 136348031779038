import { Form, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { Button } from 'src/components/button/Button'
import { InputField } from 'src/components/input/Input'
import { Modal } from 'src/components/modal/Modal'
import { useLeadListData } from 'src/service/lead-list/useLeadListData'
import { styled } from 'styled-components'
import * as Yup from 'yup'

interface IEditLeadListModal {
  visible: boolean
  leadListId: string
  onClose: () => void
  onSuccess: () => void
}

interface EditLeadListForm {
  name: string
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required')
})

export const EditLeadListModal: FC<IEditLeadListModal> = ({ visible, leadListId, onClose, onSuccess }) => {
  const { getLeadList, updateLeadList } = useLeadListData()
  const [initialValues, setInitialValues] = useState<EditLeadListForm | null>(null)

  useEffect(() => {
    const init = async () => {
      const data = await getLeadList(leadListId)
      if (data) {
        setInitialValues({ name: data.name })
      }
    }

    init()
  }, [getLeadList, leadListId])

  const onSubmit = async (values: EditLeadListForm) => {
    const data = await updateLeadList(leadListId, values)
    if (data) {
      setInitialValues(values)
      onSuccess()
    }
  }

  return (
    <>
      {initialValues && (
        <Modal visible={visible} size='small' onClose={onClose} shouldCloseOnOutsideClick={false} closeButton>
          <Title>Edit List</Title>
          <Formik<EditLeadListForm>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {() => (
              <Form>
                <InputField type='text' name='name' label='Name' placeholder='Name' />
                <Button type='submit'>Update</Button>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  )
}

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.XL};
`
