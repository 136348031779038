import { AuthLayout } from 'src/components/auth-layout/AuthLayout'
import { Login } from 'src/components/login/Login'
import styled from 'styled-components'

export const LoginPage = () => {
  return (
    <AuthLayout>
      <Container>
        <Login />
      </Container>
    </AuthLayout>
  )
}

const Container = styled.div`
  width: 400px;
`
