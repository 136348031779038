import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { Auth, getAuth } from 'firebase/auth'
import { Firestore, getFirestore } from 'firebase/firestore'
import { SplashScreen } from 'src/components/splash-screen/SplashScreen'

interface FirebaseContextType {
  loading: boolean
  analytics: Analytics | null
  auth: Auth | null
  db: Firestore | null
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(undefined)

export const FirebaseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null)
  const [auth, setAuth] = useState<Auth | null>(null)
  const [db, setDb] = useState<Firestore | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const setupFirebase = useCallback(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    const auth = getAuth(app)
    const db = getFirestore(app)
    setAnalytics(analytics)
    setAuth(auth)
    setDb(db)
    setLoading(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    setupFirebase()
  }, [setupFirebase])

  return (
    <FirebaseContext.Provider value={{ loading, analytics, auth, db }}>
      {auth ? children : <SplashScreen />}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => {
  const context = useContext(FirebaseContext)
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }
  return context
}
