import { Form, Formik } from 'formik'
import { Button } from 'src/components/button/Button'
import { InputField } from 'src/components/input/Input'
import { styled } from 'styled-components'
import * as Yup from 'yup'

interface IInviteForm {
  emails: string
}

const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue ? originalValue.split(/[\s,]+/) : []
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
})

export const InviteTeam = () => {
  const onSubmit = async (values: IInviteForm) => {
    const { emails } = values
    const emailArray = emails.replaceAll(' ', '').split(',')
    // TODO: Send invite email
    console.log(emailArray)
  }

  return (
    <Container>
      <Formik<IInviteForm> initialValues={{ emails: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <InputField name='emails' placeholder='Insert here email addresses separated by commas' />
            <Button type='submit' disabled={!isValid || isSubmitting} variant='secondary'>
              Send Invite
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
`
