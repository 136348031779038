import {
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../button/Button'
import { Icon } from '../icon/Icon'
import { ArrowDownIcon, ArrowUpIcon } from 'src/shared/icons'

interface ITable {
  data: any[]
  columns: any[]
  defaultSort?: SortingState
}

export const Table: FC<ITable> = ({ data, columns, defaultSort = [] }) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSort)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      pagination
    }
  })

  return (
    <Container>
      <TableStyled>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  $sortable={header.column.getCanSort()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                        ? 'Sort descending'
                        : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder ? null : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ fontWeight: 500 }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      <div>
                        {{
                          asc: <Icon icon={ArrowDownIcon} size={16} />,
                          desc: <Icon icon={ArrowUpIcon} size={16} />
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </div>
                  )}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
        {data.length > 0 ? (
          <>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableColumn key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableColumn>
                  ))}
                </TableRow>
              ))}
            </tbody>
            <tfoot>
              <TableRow>
                <TableFooterColumn colSpan={columns.length}>
                  <Footer>
                    <div>
                      Showing {pagination.pageIndex * pagination.pageSize + 1} -{' '}
                      {table.getRowModel().rows.length.toLocaleString()} from {table.getRowCount().toLocaleString()}
                    </div>
                    <div style={{ display: 'flex', gap: 8 }}>
                      <Button size='small' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                        {'<'}
                      </Button>
                      {Array.from(Array(table.getPageCount()).keys()).map((index) => (
                        <Button key={index} size='small' onClick={() => table.nextPage()}>
                          {index + 1}
                        </Button>
                      ))}
                      <Button size='small' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        {'>'}
                      </Button>
                    </div>
                  </Footer>
                </TableFooterColumn>
              </TableRow>
            </tfoot>
          </>
        ) : (
          <tbody>
            <TableRow>
              <TableFooterColumn colSpan={columns.length}>
                <Footer>No data available</Footer>
              </TableFooterColumn>
            </TableRow>
          </tbody>
        )}
      </TableStyled>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
`

const TableStyled = styled.table`
  width: ${({ theme }) => theme.width.full};
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-radius: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
`

const TableHeader = styled.th<{ $sortable: boolean }>`
  padding: 12px 24px;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.neutralBlack};
  text-align: left;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  cursor: ${({ $sortable }) => ($sortable ? 'pointer' : 'auto')};

  &:first-child {
    border-top-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
  }
`

const TableRow = styled.tr`
  background: ${({ theme }) => theme.color.white};
  margin: 0;
  padding: 0;

  &:hover > td {
    cursor: pointer;
    background: #f9f9fc;
  }
`

const TableColumn = styled.td`
  padding: 12px 24px;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.neutralBlack};
  text-align: left;
  font-weight: ${({ theme }) => theme.font.weight.light};
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background: #f9f9fc;
  }
`

const TableFooterColumn = styled.th`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  font-size: ${({ theme }) => theme.font.size.S};
  color: #667085;
`
