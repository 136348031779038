import { PageLayout } from 'src/components/page-layout/PageLayout'

export const MessagingPage = () => {
  return (
    <PageLayout title='Messaging'>
      <>
        <h4>Not Implemented!</h4>
      </>
    </PageLayout>
  )
}
