export const ACCOUNT_PLAN = {
  FREE: 'FREE',
  BUSINESS: 'BUSINESS'
} as const

export type TAccountPlan = keyof typeof ACCOUNT_PLAN

export const COLLECTION = {
  USERS: 'USERS',
  LEAD_LISTS: 'LEAD_LISTS'
} as const

export type TCollection = keyof typeof COLLECTION

export const LEAD_LIST_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  IMPORTED: 'IMPORTED',
  UNFINISHED: 'UNFINISHED',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR'
} as const

export type TLeadListStatus = keyof typeof LEAD_LIST_STATUS
