import { ThemeProvider } from 'styled-components'
import { theme } from 'src/theme/theme'
import { UserProvider } from './user/UserContext'
import { AllRoutes } from './routes/AllRoutes'
import { BrowserRouter } from 'react-router-dom'
import { FirebaseProvider } from './service/firebase/context/FirebaseContext'
import { ErrorProvider } from './service/context/ErrorContext'
import { Slide, ToastContainer } from 'react-toastify'
import { LocalizationProvider } from './i18n/LocalizationProvider'

export const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider>
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={false}
            theme={'light'}
            transition={Slide}
            icon={false}
          />
          <ErrorProvider>
            <FirebaseProvider>
              <UserProvider>
                <AllRoutes />
              </UserProvider>
            </FirebaseProvider>
          </ErrorProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}
