import { ACCOUNT_PLAN, COLLECTION, TAccountPlan } from 'src/shared/constants'
import { useFirebaseData } from '../firebase/hooks/useFirebaseData'
import { ROLES, ROLE_TYPE } from 'src/routes'
import { LOCALE, TLocale } from 'src/i18n/LocalizationProvider'
import { useCallback, useMemo } from 'react'

export interface IUserData {
  id: string
  firstName: string
  lastName: string
  email: string
  plan: TAccountPlan
  roles: ROLE_TYPE[]
  walkthrough: boolean
  language: TLocale
  photoUrl: string
  stripe_id: string | null
  stripe_plan: string | null
}

interface ISaveUserData {
  firstName: string
  lastName: string
  email: string
  photoUrl: string
}

export const useUserData = () => {
  const { getAll, get, store, update, destroy } = useFirebaseData(COLLECTION.USERS)

  const defaultUserData = useMemo(
    () => ({
      id: '',
      plan: ACCOUNT_PLAN.FREE,
      roles: [ROLES.MEMBER],
      walkthrough: false,
      language: LOCALE.EN,
      stripe_id: null,
      stripe_plan: null
    }),
    []
  )

  const getUsers = useCallback(async () => {
    return await getAll<IUserData>()
  }, [getAll])

  const getUser = useCallback(
    async (id: string) => {
      return await get<IUserData>(id)
    },
    [get]
  )

  const saveUser = useCallback(
    async (data: ISaveUserData, uid: string) => {
      return await store<IUserData>(
        {
          ...defaultUserData,
          ...data
        },
        uid
      )
    },
    [store, defaultUserData]
  )

  const updateUser = useCallback(
    async (id: string, data: Partial<IUserData>) => {
      return await update<Partial<IUserData>>(id, data)
    },
    [update]
  )

  const removeUser = useCallback(
    async (id: string) => {
      return await destroy(id)
    },
    [destroy]
  )

  return {
    getUsers,
    getUser,
    saveUser,
    updateUser,
    removeUser
  }
}
