import { ButtonHTMLAttributes, CSSProperties, FC, ReactNode } from 'react'
import { css, styled } from 'styled-components'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'transparent'
  size?: 'small' | 'medium' | 'large'
  children?: string | ReactNode
  disabled?: boolean
  style?: CSSProperties
}

export const Button: FC<IButton> = ({ variant = 'primary', size = 'medium', children, disabled, ...props }) => {
  return (
    <ButtonStyled
      disabled={disabled}
      $transparent={variant === 'transparent'}
      $secondary={variant === 'secondary'}
      $size={size}
      {...props}
    >
      {children}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button<{ $transparent: boolean; $secondary: boolean; disabled?: boolean; $size: string }>`
  width: ${({ theme }) => theme.width.full};
  display: inline-flex;
  padding: 10px 14px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.M};
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  transition: all 0.2s ease;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      width: 32px;
      height: 32px;
      padding: 10px;
    `}

  ${({ $secondary }) =>
    $secondary &&
    css`
      border: 1px solid ${({ theme }) => theme.color.secondary};
      background: ${({ theme }) => theme.color.secondary};
      color: ${({ theme }) => theme.color.primary};
    `};

  ${({ $transparent }) =>
    $transparent &&
    css`
      border: 1px solid ${({ theme }) => theme.color.primary};
      background: transparent;
      color: ${({ theme }) => theme.color.primary};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `};
`
