import { FC } from 'react'
import styled, { css } from 'styled-components'

export type AlertType = 'success' | 'warning' | 'danger' | 'info'

interface IAlert {
  type?: AlertType
  children: string
}

export const Alert: FC<IAlert> = ({ type = 'info', children }) => {
  return (
    <Container $type={type}>
      <Message $type={type}>{children}</Message>
    </Container>
  )
}

const Container = styled.div<{ $type: string }>`
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

  ${({ $type }) =>
    $type === 'success' &&
    css`
      background: rgba(82, 209, 105, 0.2);
      color: ${({ theme }) => theme.color.success};
    `};

  ${({ $type }) =>
    $type === 'warning' &&
    css`
      background: rgba(255, 193, 7, 0.2);
      color: ${({ theme }) => theme.color.warning};
    `};

  ${({ $type }) =>
    $type === 'danger' &&
    css`
      background: rgba(255, 23, 68, 0.2);
      color: ${({ theme }) => theme.color.danger};
    `};

  ${({ $type }) =>
    $type === 'info' &&
    css`
      background: rgba(41, 121, 255, 0.2);
      color: ${({ theme }) => theme.color.info};
    `};
`

const Message = styled.p<{ $type: string }>`
  font-size: ${({ theme }) => theme.font.size.M};
  font-weight: ${({ theme }) => theme.font.weight.light};
  opacity: 0.9;
  text-align: left;
`
