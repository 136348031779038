import { Form, Formik } from 'formik'
import { Button } from '../button/Button'
import { InputField } from '../input/Input'
import * as Yup from 'yup'
import { CheckboxField } from '../checkbox.tsx/Checkbox'
import { useUser } from 'src/user/UserContext'

interface IRegisterForm {
  firstName: string
  lastName: string
  email: string
  password: string
  terms: boolean
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(8, 'Password should be minimum of 8 characters').required('Password is required'),
  terms: Yup.boolean().required('You must agree to Terms and Privacy Policy to continue')
})

export const RegisterForm = () => {
  const { register } = useUser()

  const onSubmit = async (values: IRegisterForm) => {
    const { email, password, firstName, lastName } = values
    await register({ email, password, firstName, lastName })
  }

  return (
    <Formik<IRegisterForm>
      initialValues={{ firstName: '', lastName: '', email: '', password: '', terms: false }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form noValidate>
          <InputField name='firstName' label='Name' type='text' placeholder='Your name...' />
          <InputField name='lastName' label='Last name' type='text' placeholder='Last name...' />
          <InputField name='email' label='Email' type='email' placeholder='Your email...' />
          <InputField name='password' label='Password' type='password' placeholder='Your password...' />
          <CheckboxField name='terms' label='Agree to all the Term & Privacy Policy' />
          <Button type='submit' disabled={!isValid || isSubmitting}>
            Sign up
          </Button>
        </Form>
      )}
    </Formik>
  )
}
