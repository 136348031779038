import { FC } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'

type InputProps = {
  label: string
  name: string
  validate?: (value: any) => undefined | string | Promise<any>
}

export const CheckboxField: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <>
      <CheckboxContainer>
        <CheckboxStyled
          type='checkbox'
          {...field}
          name={props.name}
          checked={field.value}
          onChange={() => {
            const newValue = !field.value
            helpers.setValue(newValue)
          }}
        />
        <Label htmlFor={props.name}>{label}</Label>
      </CheckboxContainer>
      {meta.touched && meta.error ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
`

const Label = styled.label`
  color: #667085;
`

const CheckboxStyled = styled.input`
  margin-right: 5px;
  width: 20px;
  height: 20px;
  accent-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;
  border: 2px solid black;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.font.size.S};
  margin-top: 5px;
`
