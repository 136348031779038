import { FC } from 'react'
import styled from 'styled-components'

interface IAvatar {
  url: string
  size?: number
}

export const Avatar: FC<IAvatar> = ({ url, size = 32 }) => {
  return <Image $size={size} src={url} />
}

const Image = styled.img<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
`
