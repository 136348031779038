import styled from 'styled-components'
import SearchIcon from 'src/assets/icons/search.svg'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface ISearch {
  label: string
  onSearch: (query: string) => void
}

export const Search: FC<ISearch> = ({ label, onSearch }) => {
  const { query } = useParams()

  useEffect(() => {
    if (query && query.length > 2) {
      setLocalValue(query)
    }
  }, [query])

  const [localValue, setLocalValue] = useState<string>('')

  const onLocalChange = (e: any) => {
    const value = e.target.value.trim()
    setLocalValue(value)
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const value = e.target.value
      if (value && value.length > 2) {
        onSearch(localValue)
      }
    }
  }

  return (
    <Container>
      <Icon src={SearchIcon} />
      <Input
        name='search'
        type='search'
        value={localValue}
        placeholder={label}
        onChange={onLocalChange}
        onKeyDown={onKeyDown}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 200px;
  display: flex;
  justify-content: start;
  align-items: center;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.font.size.M};
`
