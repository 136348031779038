import { FC, ReactNode, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from '../icon/Icon'
import { CloseIcon } from 'src/shared/icons'

type TModalSize = 'small' | 'medium' | 'large'

interface IModal {
  visible: boolean
  children: ReactNode
  closeButton?: boolean
  shouldCloseOnOutsideClick?: boolean
  size?: TModalSize
  noPadding?: boolean
  onClose: () => void
}

export const Modal: FC<IModal> = ({
  visible,
  children,
  closeButton = false,
  shouldCloseOnOutsideClick = true,
  size = 'large',
  noPadding = false,
  onClose
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden'
    }
  }, [visible])

  useEffect(() => {
    if (shouldCloseOnOutsideClick) {
      const handleClickOutside = (event: any) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [modalRef, shouldCloseOnOutsideClick, onClose])

  if (visible) {
    return (
      <Container $show={visible}>
        <Main ref={modalRef} $size={size} $padding={!noPadding}>
          {closeButton && (
            <CloseButton>
              <Icon icon={CloseIcon} onClick={onClose} size={16} />
            </CloseButton>
          )}
          {children}
        </Main>
      </Container>
    )
  }

  return null
}

const Container = styled.div<{ $show: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${({ theme }) => theme.width.screen};
  height: ${({ theme }) => theme.height.screen};
  z-index: 1090;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  scroll-behavior: unset;
`

const Main = styled.div<{ $size: string; $padding: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.color.white};
  border-radius: 16px;
  padding: ${({ $padding }) => ($padding ? '16px' : '0')};

  ${({ $size }) =>
    $size === 'small' &&
    css`
      width: 350px;
      min-height: 180px;
    `}

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      width: 500px;
      height: 300px;
    `}

    ${({ $size }) =>
    $size === 'large' &&
    css`
      width: 650px;
      min-height: 650px;
    `}
`

const CloseButton = styled.div`
  width: ${({ theme }) => theme.width.full};
  display: flex;
  justify-content: flex-end;
`
