import styled from 'styled-components'
import { Icon } from '../icon/Icon'
import { LockIcon } from 'src/shared/icons'

export const NotAuthorized = () => {
  //TODO: REDESIGN
  return (
    <Container>
      <Icon icon={LockIcon} size={64} />
      <Title>Not Authorized</Title>
      <SubTitle>You are not authorized to access this page!</SubTitle>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 135px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.XXXL};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin: 16px 0;
`

const SubTitle = styled.h1`
  font-size: ${({ theme }) => theme.font.size.L};
  font-weight: ${({ theme }) => theme.font.weight.light};
`
