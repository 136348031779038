import { AuthLayout } from 'src/components/auth-layout/AuthLayout'
import { Register } from 'src/components/register/Register'
import { styled } from 'styled-components'

export const RegisterPage = () => {
  return (
    <AuthLayout>
      <Container>
        <Register />
      </Container>
    </AuthLayout>
  )
}

const Container = styled.div`
  width: 400px;
`
