import { useMemo } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { AdminLayout } from 'src/components/admin-layout/AdminLayout'
import { ROUTES } from 'src/routes'
import { ProtectedRoute } from 'src/routes/ProtectedRoute'
import { useUser } from 'src/user/UserContext'

export const AllRoutes = () => {
  const { user } = useUser()

  const routes = useMemo(() => {
    if (user) {
      return (
        <Route path='/' element={<AdminLayout />}>
          {Object.values(ROUTES)
            .filter((r) => r.roles !== undefined)
            .map(({ path, roles, Component }) => (
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute key={path} path={path} Component={Component} roles={roles} />}
              />
            ))}
        </Route>
      )
    } else {
      return (
        <Route path='/' element={<Outlet />}>
          {Object.values(ROUTES)
            .filter((r) => r.roles === undefined)
            .map(({ Component, path }, i) => (
              <Route key={i} path={path} element={<Component />} />
            ))}
        </Route>
      )
    }
  }, [user])

  return <Routes>{routes}</Routes>
}
