import { Form, Formik } from 'formik'
import { Button } from '../button/Button'
import { InputField } from '../input/Input'
import * as Yup from 'yup'
import { useFirebaseAuth } from 'src/service/firebase/hooks/useFirebaseAuth'
import { useState } from 'react'
import { Alert } from '../alert/Alert'

interface IForgotPasswordForm {
  email: string
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required')
})

export const ForgotPasswordForm = () => {
  const { firebaseForgotPassword } = useFirebaseAuth()
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const onSubmit = async (values: IForgotPasswordForm) => {
    const { email } = values
    const emailSent = await firebaseForgotPassword(email)
    setEmailSent(emailSent)
  }

  return (
    <>
      {emailSent && <Alert>Reset password email sent. Please check your email inbox.</Alert>}
      <Formik<IForgotPasswordForm>
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <InputField name='email' label='Email' type='email' placeholder='Your email...' disabled={emailSent} />
            {!emailSent && (
              <Button type='submit' disabled={!isValid || isSubmitting}>
                Send Reset Instructions
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}
