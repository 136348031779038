import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert } from 'src/components/alert/Alert'
import { FIREBASE_ERROR_MAP } from 'src/shared/firebase-errors'

interface ErrorContextType {
  handleError: (error: string) => void
  AlertErrorOutlet: () => JSX.Element
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    setMessage(null)
  }, [location])

  const handleError = useCallback((error: string) => {
    if (error.includes('Firebase')) {
      const firebaseError = FIREBASE_ERROR_MAP.find((item) => error.includes(item.key))
      if (firebaseError) {
        setMessage(firebaseError.value)
      } else {
        setMessage(error)
      }
    } else {
      setMessage(error)
    }
  }, [])

  const AlertErrorOutlet = () => {
    return <>{message ? <Alert type='danger'>{message}</Alert> : null}</>
  }

  return <ErrorContext.Provider value={{ handleError, AlertErrorOutlet }}>{children}</ErrorContext.Provider>
}

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be used within a ErrorProvider')
  }
  return context
}
