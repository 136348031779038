import { AuthLayout } from 'src/components/auth-layout/AuthLayout'
import { ResetPassword } from 'src/components/reset-password/ResetPassword'
import { styled } from 'styled-components'

export const ResetPasswordPage = () => {
  return (
    <AuthLayout>
      <Container>
        <ResetPassword />
      </Container>
    </AuthLayout>
  )
}

const Container = styled.div`
  width: 400px;
`
