import BgStepOne from 'src/assets/images/bg_step_1.png'
import BgStepTwo from 'src/assets/images/bg_step_2.png'
import BgStepThree from 'src/assets/images/bg_step_3.png'
import BgStepFour from 'src/assets/images/bg_step_4.png'

export type TWalkthrough = {
  step: number
  button: string
  title: string
  subtitle: string
  background: string
}

export const WALKTHROUGH_CONFIG: TWalkthrough[] = [
  {
    step: 0,
    button: 'Continue',
    title: 'Welcome to Digitalics',
    subtitle: 'Plan.io is a back to basic to-do-list focused on fast and delightful user experience',
    background: BgStepOne
  },
  {
    step: 1,
    button: 'Continue',
    title: 'Powerful lists',
    subtitle: 'Organise your tasks into fully customizable list with emojis and dynamic color support',
    background: BgStepTwo
  },
  {
    step: 2,
    button: 'Continue',
    title: 'Customizable shortcuts',
    subtitle: 'Plan.io offers lot of customizations, one of them is allowing you to choose your own shortcuts.',
    background: BgStepThree
  },
  {
    step: 3,
    button: 'Get Started',
    title: 'That’s it!',
    subtitle: 'Let’s get some things done. Don’t forget to follow us on X to stay updated.',
    background: BgStepFour
  }
]
