import styled from 'styled-components'
import { Modal } from '../modal/Modal'
import { Button } from '../button/Button'
import { FC } from 'react'

interface IConfirmationModal {
  visible: boolean
  title: string
  message: string
  confirmButtonText?: string
  cancelButtonText?: string
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmationModal: FC<IConfirmationModal> = ({
  visible,
  title,
  message,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onClose,
  onConfirm
}) => {
  return (
    <Modal visible={visible} onClose={onClose} size='small' closeButton shouldCloseOnOutsideClick>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ActionContainer>
        <Actions>
          <Button variant='transparent' onClick={onClose}>
            {cancelButtonText}
          </Button>
          <Button variant='primary' onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </Actions>
      </ActionContainer>
    </Modal>
  )
}

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.XL};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  margin-bottom: 10px;
`

const Message = styled.div`
  margin-top: 10px;
`

const ActionContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
`

const Actions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`
