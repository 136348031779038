import { useFirebase } from '../context/FirebaseContext'
import {
  DocumentData,
  Timestamp,
  WithFieldValue,
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection as fCollection,
  deleteDoc
} from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { TCollection } from 'src/shared/constants'

export const useFirebaseData = (collection: TCollection) => {
  const { db } = useFirebase()

  const currentTimestamp = useMemo(() => Timestamp.fromDate(new Date()), [])

  /**
   * Get all documents from collection
   *
   * @returns list of documents as success and null for failure
   */
  const getAll = useCallback(async <T extends WithFieldValue<DocumentData>>(): Promise<T[] | null | undefined> => {
    try {
      if (db) {
        const data: T[] = []
        const snap = await getDocs(fCollection(db, collection))

        snap.forEach((doc) => {
          data.push(doc.data() as T)
        })

        return data
      }
    } catch (err: any) {
      toast(err.message, { type: 'error' })
      return null
    }
  }, [db, collection])

  /**
   * Get document from collection
   *
   * @param id the id of the document or by default uuid
   * @returns document as success and null for failure
   */
  const get = useCallback(
    async <T extends WithFieldValue<DocumentData>>(id: string): Promise<T | null | undefined> => {
      try {
        if (db) {
          const document = doc(db, collection, id)
          const data = await getDoc(document)

          return data.data() as T
        }
      } catch (err: any) {
        toast(err.message, { type: 'error' })
        return null
      }
    },
    [db, collection]
  )

  /**
   * Adds a new document to collection
   *
   * @param data data to be added
   * @param id the id of the document or by default uuid
   * @returns id as success and null for failure
   */
  const store = useCallback(
    async <T extends WithFieldValue<DocumentData>>(
      data: T,
      id: string = uuidv4()
    ): Promise<string | null | undefined> => {
      try {
        if (db) {
          const document = doc(db, collection, id)
          const docData = await getDoc(document)
          if (docData.exists()) {
            return null
          }
          await setDoc(
            doc(db, collection, id),
            {
              ...data,
              id: id,
              created_at: currentTimestamp,
              updated_at: currentTimestamp
            },
            { merge: false }
          )
          return id
        }
      } catch (err: any) {
        toast(err.message, { type: 'error' })
        return null
      }
    },
    [db, collection, currentTimestamp]
  )

  /**
   * Updates a document from collection by id
   *
   * @param id the id of the document
   * @param data data to be updated
   * @returns id as success and null for failure
   */
  const update = useCallback(
    async <T extends WithFieldValue<DocumentData>>(id: string, data: T): Promise<string | null | undefined> => {
      try {
        if (db) {
          const document = doc(db, collection, id)
          await setDoc(document, { ...data, updated_at: currentTimestamp }, { merge: true })
          return id
        }
      } catch (err: any) {
        toast(err.message, { type: 'error' })
        return null
      }
    },
    [db, collection, currentTimestamp]
  )

  /**
   * Deletes a document from collection by id
   *
   * @param id the id of the document
   * @returns void
   */
  const destroy = useCallback(
    async (id: string): Promise<void> => {
      try {
        if (db) {
          await deleteDoc(doc(db, collection, id))
        }
      } catch (err: any) {
        toast(err.message, { type: 'error' })
        return
      }
    },
    [db, collection]
  )

  return {
    getAll,
    get,
    store,
    update,
    destroy
  }
}
