import { Button } from 'src/components/button/Button'
import { Icon } from 'src/components/icon/Icon'
import LinkedInLogo from 'src/assets/images/linkedin.svg'

export const ImportLeads = () => {
  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/', '_blank')
  }

  return (
    <Button variant='transparent' onClick={openLinkedIn}>
      <Icon icon={LinkedInLogo} />
      Open LinkedIn account
    </Button>
  )
}
