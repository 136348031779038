import { styled } from 'styled-components'
import ChromeLogo from 'src/assets/images/chrome.svg'
import { Button } from 'src/components/button/Button'

export const DownloadExtension = () => {
  return (
    <DownloadSection>
      <img className='logo' src={ChromeLogo} alt='chrome-logo' />
      <Button variant='secondary'>Download</Button>
    </DownloadSection>
  )
}

const DownloadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    margin-right: 14px;
  }
`
