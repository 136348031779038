import { FC } from 'react'
import { useUser } from 'src/user/UserContext'
import styled from 'styled-components'
import { Button } from './button/Button'

export const Welcome: FC<{ title: string }> = ({ title }) => {
  const { user, logout } = useUser()
  return (
    <Container>
      <Hello>{title}</Hello>
      <div>{user && <Button onClick={logout}>Log Out</Button>}</div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Hello = styled.h1`
  font-size: ${({ theme }) => theme.font.size.XXXL};
  font-weight: ${({ theme }) => theme.font.weight.light};
`
