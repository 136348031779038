import { FC, ReactNode } from 'react'
import { devices } from 'src/theme/theme'
import styled from 'styled-components'
import logo from 'src/assets/images/logo.svg'

export const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container>
      <LogoPreview>
        <img src={logo} alt='digitalics-innovation-logo' />
      </LogoPreview>
      <FormView>{children}</FormView>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  overflow: hidden;
`

const LogoPreview = styled.div`
  flex: 1;
  height: calc(100vh - 48px);
  background: linear-gradient(200.38deg, #cbcbcb 13.34%, #353787 128.62%);
  margin: 24px;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.mobile} {
    display: none;
  }
`

const FormView = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 24px 0;
  overflow: scroll;
`
