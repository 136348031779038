import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const useDelayedNavigate = () => {
  const navigate = useNavigate()
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const delayedNavigate = (to: string) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = window.setTimeout(() => {
      navigate(to)
    }, 200)
  }

  return delayedNavigate
}
