import { Button } from 'src/components/button/Button'
import { useDelayedNavigate } from 'src/hooks/useDelayedNavigate'
import { ROUTES } from 'src/routes'
import { devices } from 'src/theme/theme'
import styled from 'styled-components'

export const LandingPage = () => {
  const navigate = useDelayedNavigate()

  const onContinueHandle = () => {
    navigate(ROUTES.LoginPage.path)
  }

  return (
    <Container>
      <Button onClick={onContinueHandle}>Try for free</Button>
    </Container>
  )
}

const Container = styled.div`
  width: 500px;
  height: ${({ theme }) => theme.height.screen};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;

  @media ${devices.mobile} {
    width: ${({ theme }) => theme.width.full};
  }
`
