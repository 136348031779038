import React from 'react'
import ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import { Extension } from './Extension'
import { App } from './App'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

export const BUILD_TARGET = {
  EXTENSION: 'EXTENSION',
  WEB: 'WEB'
} as const

export type BUILD_TARGET_TYPE = keyof typeof BUILD_TARGET

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Target = () => {
  return process.env.REACT_APP_BUILD_TARGET === BUILD_TARGET.EXTENSION ? <Extension /> : <App />
}

root.render(
  <React.StrictMode>
    <Target />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
