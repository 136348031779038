import { FC } from 'react'
import styled from 'styled-components'
import { Icon } from '../icon/Icon'

interface IStatisticCard {
  label: string
  value: string
  icon: string
}

export const StatisticCard: FC<IStatisticCard> = ({ label, value, icon }) => {
  return (
    <Container>
      <Row>
        <Label>{label}</Label>
        <Icon icon={icon} size={36} />
      </Row>
      <Value>{value}</Value>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 2px rgba(46, 45, 116, 0.05);
  border-radius: 12px;
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const Label = styled.p`
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.font.size.M};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`

const Value = styled.p`
  color: ${({ theme }) => theme.color.neutralBlack};
  font-size: ${({ theme }) => theme.font.size.XXL};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`
