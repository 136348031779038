import { I18n } from 'i18n-js'
import { Scope, TranslateOptions } from 'i18n-js/typings/typing'
import React, { createContext, useEffect, useMemo, useState } from 'react'

import EN from 'src/assets/locales/en/translations.json'
import IT from 'src/assets/locales/it/translations.json'

export const LOCALE = {
  EN: 'EN',
  IT: 'IT'
} as const

export type TLocale = keyof typeof LOCALE

const _LOCALE = LOCALE.EN
const i18n = new I18n({ EN, IT }, { enableFallback: true })

interface Props {
  children: React.ReactNode
}

interface LocaleContextInterface {
  t: (scope: Scope, options?: TranslateOptions | undefined) => string
  locale: TLocale
  setLocale: (value: TLocale) => void
}

const DefaultLocale: LocaleContextInterface = {
  t: () => '',
  locale: _LOCALE,
  setLocale: () => {}
}

export const LocalizationContext = createContext<LocaleContextInterface>(DefaultLocale)

export const LocalizationProvider = ({ children }: Props) => {
  const [locale, setLocale] = useState<TLocale>(_LOCALE)

  const localizationContext = useMemo(
    () => ({
      t: (scope: Scope, options: TranslateOptions | undefined) => i18n.t(scope, { locale, ...options }),
      locale,
      setLocale
    }),
    [locale]
  )

  useEffect(() => {
    const locale = _LOCALE
    if (locale) {
      setLocale(locale)
    }
  }, [])

  return <LocalizationContext.Provider value={localizationContext}>{children}</LocalizationContext.Provider>
}
