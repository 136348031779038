import { Outlet } from 'react-router-dom'
import { styled } from 'styled-components'
import { SIDEBAR_WIDTH, Sidebar } from '../sidebar/Sidebar'
import { Header } from '../header/Header'
import { devices } from 'src/theme/theme'

export const AdminLayout = () => {
  return (
    <Container>
      <Sidebar />
      <Main>
        <Header />
        <Outlet />
      </Main>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f9f9fc;
  height: ${({ theme }) => theme.height.full};
`

const Main = styled.div`
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  padding: 24px;

  @media ${devices.mobile} {
    width: 100%;
  }
`
