import { styled } from 'styled-components'
import logo from 'src/assets/images/logo.svg'
import { devices } from 'src/theme/theme'

export const SplashScreen = () => {
  return (
    <Splash>
      <SplashImage src={logo} alt='digitalics-innovation-logo' />
    </Splash>
  )
}

const Splash = styled.div`
  width: ${({ theme }) => theme.width.screen};
  height: ${({ theme }) => theme.height.screen};
  background-color: ${({ theme }) => theme.color.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
`

const SplashImage = styled.img`
  width: 300px;
  height: 300px;

  @media ${devices.mobile} {
    width: 150px;
    height: 150px;
  }
`
