import { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface IPageLayout {
  title: string
  children: ReactNode
  action?: ReactNode
}

export const PageLayout: FC<IPageLayout> = ({ title, children, action }) => {
  return (
    <Container>
      <Row>
        <Title>{title}</Title>
        <div>{action}</div>
      </Row>
      <Content>{children}</Content>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.XXL};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.neutralBlack};
`

const Content = styled.div`
  margin-top: 24px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`
