import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import { ResetPasswordForm } from './ResetPasswordForm'
import { useError } from 'src/service/context/ErrorContext'
import { useState } from 'react'
import { Alert } from '../alert/Alert'

export const ResetPassword = () => {
  const { AlertErrorOutlet } = useError()
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false)

  const onPasswordReset = (value: boolean) => {
    setPasswordResetSuccess(value)
  }

  return (
    <Container>
      <Title>Reset password</Title>
      <AlertErrorOutlet />
      {passwordResetSuccess && <Alert>Your password is reset successfully.</Alert>}
      {!passwordResetSuccess ? (
        <>
          <ResetPasswordForm onPasswordReset={onPasswordReset} />
          <SubTitle style={{ marginTop: 5 }}>
            Don't have an account?{' '}
            <Link to={ROUTES.RegisterPage.path} className='link'>
              Register now
            </Link>
          </SubTitle>
        </>
      ) : (
        <SubTitle style={{ marginTop: 15 }}>
          Please{' '}
          <Link to={ROUTES.LoginPage.path} className='link'>
            login
          </Link>{' '}
          with your new password.
        </SubTitle>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
  text-align: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.XXL};
  margin-bottom: 10px;
`

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.M};
  margin-bottom: 32px;

  .link {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    text-decoration: none;
  }
`
