import { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'

interface ICard {
  title: string
  description?: string
  children: ReactNode
  style?: CSSProperties
}

export const Card: FC<ICard> = ({ title, description, children, style }) => {
  return (
    <CardContainer style={style}>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <CardContent>{children}</CardContent>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  width: ${({ theme }) => theme.width.full};
  background: ${({ theme }) => theme.color.white};
  padding: 24px;
  min-height: 260px;
  border-radius: 12px;
`

const CardTitle = styled.h3`
  color: ${({ theme }) => theme.color.neutralBlack};
  font-size: ${({ theme }) => theme.font.size.L};
  margin-bottom: 14px;
`

const CardDescription = styled.p`
  color: #777980;
  font-size: ${({ theme }) => theme.font.size.S};
  margin-bottom: 4px;
`

const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 140px;
`
