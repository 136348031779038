export const theme = {
  color: {
    white: '#ffffff',
    black: '#000000',
    primary: '#0C2440',
    secondary: 'rgba(12, 36, 64, 0.1)',
    grey: '#777980',
    lightGrey: '#E0E2E7',
    darkGrey: '#858D9D',
    success: '#52D169',
    warning: '#FFC107',
    danger: '#FF1744',
    info: '#2979FF',
    neutralBlack: '#1D1F2C'
  },
  width: {
    full: '100%',
    screen: '100vw'
  },
  height: {
    full: '100%',
    screen: '100vh'
  },
  font: {
    size: {
      XS: '0.75rem', // 12px
      S: '0.875rem', // 14px
      M: '1rem', // 16px
      L: '1.125rem', // 18px
      XL: '1.25rem', // 20px
      XXL: '1.5rem', // 24px
      XXXL: '1.75rem' // 28px
    },
    weight: {
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900
    }
  }
}

const sizes = {
  mobile: '480px',
  tablet: '1024px',
  desktop: '2560px'
}

export const devices = {
  mobile: `screen and (max-width: ${sizes.mobile})`,
  tablet: `screen and (max-width: ${sizes.tablet})`,
  desktop: `screen and (max-width: ${sizes.desktop})`
}
