import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import { useError } from 'src/service/context/ErrorContext'
import { ForgotPasswordForm } from './ForgotPasswordForm'

export const ForgotPassword = () => {
  const { AlertErrorOutlet } = useError()

  return (
    <Container>
      <Title>Forgot password</Title>
      <SubTitle>
        Enter the email address you used when joined and we'll send reset instructions to reset your password
      </SubTitle>
      <AlertErrorOutlet />
      <ForgotPasswordForm />
      <SubTitle style={{ marginTop: 5 }}>
        Back to login page?{' '}
        <Link to={ROUTES.LoginPage.path} className='link'>
          Back to login
        </Link>
      </SubTitle>
    </Container>
  )
}

const Container = styled.div`
  width: ${({ theme }) => theme.width.full};
  text-align: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.font.size.XXL};
  margin-bottom: 10px;
`

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.M};
  margin-bottom: 32px;

  .link {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    text-decoration: none;
  }
`
