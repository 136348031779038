import { useNavigate } from 'react-router-dom'
import { Button } from 'src/components/button/Button'
import { ROUTES } from 'src/routes'

export const FirstCampaign = () => {
  const navigate = useNavigate()

  const onContinue = () => {
    navigate(ROUTES.CampaignsPage.path)
  }

  return (
    <Button variant='secondary' onClick={onContinue}>
      Go to Campaigns
    </Button>
  )
}
