import { FC, useState } from 'react'
import { Stepper } from './Stepper'
import { WALKTHROUGH_CONFIG } from 'src/shared/walkthrough-config'

interface IWalkthrough {
  onDone: () => void
}

export const Walkthrough: FC<IWalkthrough> = ({ onDone }) => {
  const [step, setStep] = useState(0)
  const [item, setItem] = useState(WALKTHROUGH_CONFIG[0])

  const onNextStep = () => {
    if (step === 3) {
      onDone()
      return
    }
    setStep(step + 1)
    setItem(WALKTHROUGH_CONFIG?.[step + 1])
  }

  return <Stepper item={item} onClick={onNextStep} />
}
