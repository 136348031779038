import { AuthLayout } from 'src/components/auth-layout/AuthLayout'
import { ForgotPassword } from 'src/components/forgot-password/ForgotPassword'
import { styled } from 'styled-components'

export const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <Container>
        <ForgotPassword />
      </Container>
    </AuthLayout>
  )
}

const Container = styled.div`
  width: 400px;
`
