import { Form, Formik } from 'formik'
import { Button } from '../button/Button'
import { InputField } from '../input/Input'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import styled from 'styled-components'
import { useUser } from 'src/user/UserContext'
import { Icon } from '../icon/Icon'
import { HiddenIcon, VisibleIcon } from 'src/shared/icons'
import { useState } from 'react'

interface ILoginForm {
  email: string
  password: string
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(8, 'Password should be minimum of 8 characters').required('Password is required')
})

export const LoginForm = () => {
  const { login } = useUser()
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const onSubmit = async (values: ILoginForm) => {
    await login(values)
  }

  return (
    <Formik<ILoginForm>
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form noValidate>
          <InputField name='email' label='Email' type='email' placeholder='Your email...' />
          <InputField
            name='password'
            label='Password'
            type={passwordVisible ? 'text' : 'password'}
            placeholder='Your password...'
            additionalLabel={
              <ForgotPasswordLink to={ROUTES.ForgotPasswordPage.path} className='link'>
                Forget Password
              </ForgotPasswordLink>
            }
            postfixIcon={
              <Icon
                icon={passwordVisible ? HiddenIcon : VisibleIcon}
                onClick={() => setPasswordVisible(!passwordVisible)}
                size={22}
                style={{ opacity: 0.5 }}
              />
            }
          />
          <Button type='submit' disabled={!isValid || isSubmitting}>
            Log in
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  text-decoration: none;
`
