import { LocalizationContext, TLocale } from './LocalizationProvider'
import { useContext } from 'react'

export const useMessageSource = () => {
  const { t, setLocale } = useContext(LocalizationContext)

  const getMessage = (value: string, params?: Object) => {
    if (value) {
      let translated = t(value)
      // If value doesn't exists in the json file
      if (translated.includes('missing')) {
        return value
      }
      // Replace the params key-values to the translation
      if (params && Object.values(params).length > 0) {
        Object.entries(params).forEach(([key, value]) => {
          translated = translated.replace(`::${key}`, value)
        })
      }
      return translated
    }
    return ''
  }

  const changeLocale = (locale: TLocale) => {
    return setLocale(locale)
  }

  return {
    getMessage,
    changeLocale
  }
}
