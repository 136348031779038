import { FC } from 'react'
import { styled } from 'styled-components'

export const Divider: FC<{ text?: string }> = ({ text = 'or' }) => {
  return (
    <SplitLine>
      <SplitLineContainer />
      <SplitLineText>{text}</SplitLineText>
      <SplitLineContainer />
    </SplitLine>
  )
}

const SplitLine = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;
`

const SplitLineContainer = styled.div`
  width: 100%;
  height: 1px;
  flex: 4;
  background: ${({ theme }) => theme.color.lightGrey};
`

const SplitLineText = styled.p`
  flex: 1;
  color: ${({ theme }) => theme.color.darkGrey};
`
