import { ElementType, FC } from 'react'
import { NotAuthorized } from 'src/components/not-authorized/NotAuthorized'
import { ROLE_TYPE, isPathAllowedForRole } from 'src/routes'
import { useUser } from 'src/user/UserContext'

interface ProtectedRouteProps {
  path: string
  roles?: ROLE_TYPE[]
  Component: ElementType
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ path, roles, Component, ...props }) => {
  const { user } = useUser()

  if (user && user.roles.length > 0) {
    const { roles } = user
    if (isPathAllowedForRole(path, roles)) {
      return <Component {...props} />
    } else {
      return <NotAuthorized />
    }
  }

  return <NotAuthorized />
}
