import { FC } from 'react'
import styled, { css } from 'styled-components'

type TChipSize = 'small' | 'medium' | 'large'

interface IChip {
  label: string
  size?: TChipSize
}

export const Chip: FC<IChip> = ({ label, size = 'medium' }) => {
  return <Container $size={size}>{label.toUpperCase()}</Container>
}

const Container = styled.div<{ $size: string }>`
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  font-weight: ${({ theme }) => theme.font.weight.medium};

  ${({ $size }) =>
    $size === 'small' &&
    css`
      font-size: ${({ theme }) => theme.font.size.XS};
    `};

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      font-size: ${({ theme }) => theme.font.size.S};
    `};

  ${({ $size }) =>
    $size === 'large' &&
    css`
      font-size: ${({ theme }) => theme.font.size.M};
    `};
`
